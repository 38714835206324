<template>
  <div>
    <!-- Web Content -->
    <div class="top_head_section">
      <!-- Top Tabs -->
      <top-header></top-header>

      <span v-if="isDealer && !impersonator">
        <contact_details v-bind:isProfileContactModalOpen="isProfileContactModalOpen" v-bind:profileData="profileData"></contact_details>
        <dwc_contact_details></dwc_contact_details>
        <billing_contact_details></billing_contact_details>
      </span>
      <!-- Tab Content -->
      <div :class="{'genesis':isGenesis, 'acura':isAcura, 'infiniti':isInfiniti}" class="tab-content">
        <!-- Tab Customer Portal -->
        <div class="overlay" style="position: fixed;" v-if="videoActive"></div>
        <div id="customerportal" class="tab-pane fade in active">
          <!-- Header / Navigation -->
          <header-component></header-component>
          <!-- Header / Navigation Ends Here -->
          <!-- Home Head Note -->
          <!--  Home Head Note -->
          <!-- Carousel -->
          <span v-if="!isSsoLoginOnlyAllowed">
                    <section>
                        <div class="col-lg-8 offset-lg-2 videoContainer" v-show="videoActive">
                            <svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" alt="icon" id="close-x" xmlns="http://www.w3.org/2000/svg"
                                 @click="closeVideo"
                                 fill="currentColor" class="bi-x d-none d-lg-inline b-icon bi closeVideo"
                                 style="position: absolute; color: white; z-index: 450; right: 0; height: 35px; width: 35px; opacity: 0.5;">
                                <g>
                                    <path fill-rule="evenodd" d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z" clip-rule="evenodd"></path>
                                    <path fill-rule="evenodd" d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z" clip-rule="evenodd"></path>
                                </g>
                            </svg>
                      <video v-if="(UserDetails && UserDetails.oemName === 'Honda')" controls="controls" src="https://unityworks-a.akamaihd.net/anon.unityworks/L/E/W/43874219_HDCX_Video_200602022634_4.mp4"
                             type="video/mp4" class="embed-responsive video" id="hondaVideo" playsinline></video>

                          <video v-if="(UserDetails && UserDetails.oemName === 'Acura')" controls="controls" src="https://unityworks-a.akamaihd.net/anon.unityworks/2/3/K/48884091_Digital_Dealer_Program_2.0_230221b_230221102527_4.mp4"
                                 type="video/mp4" class="embed-responsive video" id="acuraVideo" playsinline></video>
                            <div style="width: 100%; height: 100%;"></div>
                        </div>

                        <div class="hondaHomePage" v-if="(UserDetails && UserDetails.oemName === 'Honda')">


                            <div class="row w-100 ml-auto mr-auto index-content" style="max-width: 1200px;margin: auto;">
                                <div id="hero-message-container" class="col-xl-auto">
                                    <div class="d-md-none" style="position: absolute; height: 40px; width: 100%; z-index: 7;bottom: 0;">
                                        <h1 class="hero-message-description" style="position: absolute;">
                                            The new Honda Digital Solutions offers
                                            your dealership a suite of digital products and services
                                            designed to optimize the way you do business
                                        </h1>
                                    </div>
                                    <img id="video-thumb" src="../assets/images/png/honda/video_thumb.jpg" @click="playVideo('hondaVideo')"/>
                                </div>
                            </div>
                            <div>
                                <div data-v-178dc7f2="" id="cover-img" class="row"></div>
                            </div>

                        </div>
                        <div class="hondaHomePage" v-if="(UserDetails && UserDetails.oemName === 'Acura')">
                           <div class="row w-100 ml-auto mr-auto index-content" style="max-width: 1200px;margin: auto;">
                              <div id="hero-message-container" class="col-xl-auto">
                                 <div class="d-md-none" style="position: absolute; height: 40px; width: 100%; z-index: 7;bottom: 0;">
                                    <h1 class="hero-message-description" style="position: absolute;">
                                       The new Acura Digital Program offers
                                       your dealership a suite of digital products and services
                                       designed to optimize the way you do business
                                    </h1>
                                 </div>
                                 <img id="video-thumb" src="../assets/images/png/honda/video_thumb.jpg" @click="playVideo('acuraVideo')"/>
                              </div>
                           </div>
                           <div>
                              <div data-v-178dc7f2="" id="cover-img" class="row"></div>
                           </div>
                        </div>
                        <div v-if="isInfinitiOEM">
                              <div data-v-178dc7f2="" id="cover-img" class="img-responsive"></div>
                        </div>
                        <div class="carousel_box home_carousel_box" v-if="(UserDetails && UserDetails.oemName !== 'Honda' && !isInfinitiOEM && UserDetails.oemName !== 'Acura') && bannerData">
                            <div class="container-fluid">
                              <carousel class="carousel slide" :data="bannerRenderData" :controls="true"></carousel>
                            </div>
                            <div class="container genesis-golden-frame hide">
                            </div>
                        </div>
                    </section>
            <!-- Carousel Ends Here -->
            <!-- Product Section -->
                    <section class="pos_rel">
                        <div class="products_main_section">
                            <div class="container">
                                <div v-if="isHondaOEM" class="pro_heading">
                                    <h3>DIGITAL CUSTOMER EXPERIENCE OFFERINGS</h3>
                                    <p> To learn more about the Honda program components, click on any of the products and services below.
                                        <span v-if="isDealer">
                      If you are familiar with the program and would like to skip to enrollment
                      <a class="click-here-link" href="javascript:void(0)" @click="redirectToNewTab($routePath.getRouter('enrolment'))"> click here. </a>
                    </span>
                                    </p>
                                </div>
                                <div v-else-if="isAcuraOEM" class="pro_heading">
                                   <h3>DIGITAL CUSTOMER EXPERIENCE OFFERINGS</h3>
                                   <p> To learn more about the Acura program components, click on any of the products and services below.
                                      <span v-if="isDealer">
                                      If you are familiar with the program and would like to skip to enrollment
                                      <a class="click-here-link" href="javascript:void(0)" @click="redirectToNewTab($routePath.getRouter('enrolment'))"> click here. </a>
                                      </span>
                                   </p>
                                </div>
                                <div v-else-if="!isAdmin && !isJDPAdmin" class="pro_heading">
                                    <h3>{{ $t("app.AUTODATA_MARKETING_PROGRAMS_HEADER") }}</h3>
                                </div>
                              <!-- Product Boxes -->
                                <div class="pro_content" :style="isAdmin || isJDPAdmin ? 'margin-top: 100px;' : ''">
                                    <div class="row" v-if="UserDetails">
                                        <div v-for="child in headerMenuData" :key="child.name">
                                            <div v-if="(!isHondaOEM && !isAcuraOEM && child.name === 'Products') || (isHondaOEM && child.name === 'Services') || (isAcuraOEM && child.name === 'Services')">
                                                <div class="col-xs-12 col-sm-6 col-md-4 pro_line" v-for="product in child.filteredChildren" :key="product.name">
                                                    <div class="">
                                                        <a href="javascript:void(0)" @click="redirectWhenNotEditing(product.url)">
                                                            <div v-if="isHondaOEM" class="pro_contbox">
                                                                <img v-if="product.url === 'DigitalCertification'" src="../assets/images/svg/HDS-Certified-Providers.svg"
                                                                     class="img-responsive pro_icon min-height-135" :alt="product.name" :title="product.name">
                                                                <img v-if="product.url === 'EnterpriseServices'" src="../assets/images/svg/honda_Enterprise_Service.svg"
                                                                     class="img-responsive pro_icon min-height-135" :alt="product.name" :title="product.name">
                                                                <img v-if="product.url === 'marketPlaceServices'" src="../assets/images/svg/honda_Leads.svg" class="img-responsive pro_icon min-height-135"
                                                                     :alt="product.name" :title="product.name">
                                                                <img v-if="product.url === 'DrsServices'" src="../assets/images/svg/honda_Digital_Retailing.svg" class="img-responsive pro_icon min-height-135 drs-tile-height"
                                                                     :alt="product.name" :title="product.name">
                                                                <img v-if="product.url === 'oemSmartAnalytics'" src="../assets/images/svg/honda_SmartAnalytics.svg"
                                                                     class="img-responsive pro_icon min-height-135" :alt="product.name" :title="product.name">
                                                                <img v-if="product.url === 'FieldSupport'" src="../assets/images/svg/honda_Field_Support.svg"
                                                                     class="img-responsive pro_icon min-height-135" :alt="product.name" :title="product.name">

                                                                <div class="product-card" :style="productCardStyle">
                                                                    <h4>{{replaceVars(product.name)}}</h4>
                                                                    <p v-if="!isCmsEditModeOn && !isCmsPreviewModeOn"
                                                                        v-html="getTeaser(product.name)">
                                                                    </p>
                                                                    <p v-if="isCmsEditModeOn || isCmsPreviewModeOn"
                                                                        :class="{ 'changedIndicator': getDraftTeaser(product.name) != null && !isCmsPreviewModeOn, 'editableIndicator': isCmsEditModeOn }">
                                                                            <Editor :id="getEscapedPageId(product.name)"
                                                                            :contentEditable="!isCmsPreviewModeOn"
                                                                            :init="initInlineEditorConf"
                                                                            :initialValue="getEditedTeaser(product.name)"
                                                                            @input="changeSaveCmsButton(getPageId(product.name), true)">
                                                                            </editor>
                                                                    </p>
                                                                    <img
                                                                        v-if="isCmsEditModeOn && visibleSaveCmsButtons[getPageId(product.name)]"
                                                                        class='cms_icon_width'
                                                                        @click="saveDraft(getPageId(product.name))"
                                                                        src="../assets/images/svg/save_draft_icon.svg"
                                                                        title="Save"
                                                                    />
                                                                    <img
                                                                        v-if="isCmsEditModeOn && getDraftTeaser(product.name) != null"
                                                                        class='cms_icon_width'
                                                                        @click="discardDraft(getPageId(product.name))"
                                                                        src="../assets/images/svg/discard_block_change_icon.svg"
                                                                        title="Discard"
                                                                    />
                                                                </div>
                                                                <a v-if="!isCmsEditModeOn" href="javascript:void(0)" @click="redirectTo($routePath.getRouter(product.url))" class="probox_btn" type="button">{{ $t("app.LEARN_MORE") }}</a>
                                                            </div>
                                                            <div v-else-if="isAcuraOEM" class="pro_contbox">
                                                               <img v-if="product.url === 'DigitalCertification'" src="../assets/images/svg/acura_HDS_Certified_White_Providers.svg"
                                                                  class="img-responsive pro_icon min-height-135" :alt="product.name" :title="product.name">
                                                               <img v-if="product.url === 'EnterpriseServices'" src="../assets/images/svg/acura_Ent_Serv.svg"
                                                                  class="img-responsive pro_icon min-height-135" :alt="product.name" :title="product.name">
                                                               <img v-if="product.url === 'marketPlaceServices'" src="../assets/images/svg/acura_Leads.svg" class="img-responsive pro_icon min-height-135"
                                                                  :alt="product.name" :title="product.name">
                                                               <img v-if="product.url === 'DrsServices'" src="../assets/images/svg/acura_Digital_Retailing.svg" class="img-responsive pro_icon min-height-135 drs-tile-height"
                                                                  :alt="product.name" :title="product.name">
                                                               <img v-if="product.url === 'oemSmartAnalytics'" src="../assets/images/svg/acura_SmartAnalytics.svg"
                                                                  class="img-responsive pro_icon min-height-135" :alt="product.name" :title="product.name">
                                                               <img v-if="product.url === 'FieldSupport'" src="../assets/images/svg/acura_Field_Support.svg"
                                                                  class="img-responsive pro_icon min-height-135" :alt="product.name" :title="product.name">
                                                               <div class="product-card" :style="productCardStyle">
                                                                  <h4>{{replaceVars(product.name)}}</h4>
                                                                    <p v-if="!isCmsEditModeOn && !isCmsPreviewModeOn"
                                                                        v-html="getTeaser(product.name)">
                                                                    </p>
                                                                    <p v-if="isCmsEditModeOn || isCmsPreviewModeOn"
                                                                        :class="{ 'changedIndicator': getDraftTeaser(product.name) != null && !isCmsPreviewModeOn, 'editableIndicator': isCmsEditModeOn }">
                                                                            <Editor :id="getEscapedPageId(product.name)"
                                                                            :contentEditable="!isCmsPreviewModeOn"
                                                                            :init="initInlineEditorConf"
                                                                            :initialValue="getEditedTeaser(product.name)"
                                                                            @input="changeSaveCmsButton(getPageId(product.name), true)">
                                                                            </editor>
                                                                    </p>
                                                                    <img
                                                                        v-if="isCmsEditModeOn && visibleSaveCmsButtons[getPageId(product.name)]"
                                                                        class='cms_icon_width'
                                                                        @click="saveDraft(getPageId(product.name))"
                                                                        src="../assets/images/svg/save_draft_icon.svg"
                                                                        title="Save"
                                                                    />
                                                                    <img
                                                                        v-if="isCmsEditModeOn && getDraftTeaser(product.name) != null"
                                                                        class='cms_icon_width'
                                                                        @click="discardDraft(getPageId(product.name))"
                                                                        src="../assets/images/svg/discard_block_change_icon.svg"
                                                                        title="Discard"
                                                                    />
                                                               </div>
                                                               <a v-if="!isCmsEditModeOn" href="javascript:void(0)" @click="redirectTo($routePath.getRouter(product.url))" class="probox_btn" type="button">{{ $t("app.LEARN_MORE") }}</a>
                                                            </div>
                                                            <div v-else-if="isInfinitiOEM" class="pro_contbox">
                                                                <img v-if="product.name === 'SmartLeads'" src="../assets/images/png/infiniti/smartleads-infiniti.png" class="img-responsive pro_icon min-height-135" alt="SmartLeads"
                                                                     title="SmartLeads">
                                                                <img v-if="product.name === 'Concierge Elite Service'" src="../assets/images/png/infiniti/concierge-infiniti.png" class="img-responsive pro_icon min-height-135"
                                                                     alt="Concierge Elite Service" title="Concierge Elite Service">
                                                               <img v-if="replaceVars(product.name) === replaceVars('{Dealer} Website Certification')" src="../assets/images/png/infiniti/dwc-infiniti.png"
                                                                  class="img-responsive pro_icon min-height-135" :alt="product.name" :title="product.name">
                                                               <img v-if="replaceVars(product.name) === replaceVars('Dealer Website Compliance')" src="../assets/images/png/infiniti/dwc-infiniti.png"
                                                                  class="img-responsive pro_icon min-height-135" :alt="product.name" :title="product.name">
                                                               <div class="product-card" :style="productCardStyle">
                                                                  <h4>{{replaceVars(product.name)}}</h4>
                                                                    <p v-if="!isCmsEditModeOn && !isCmsPreviewModeOn"
                                                                        v-html="getTeaser(product.name)">
                                                                    </p>
                                                                    <p v-if="isCmsEditModeOn || isCmsPreviewModeOn"
                                                                        :class="{ 'changedIndicator': getDraftTeaser(product.name) != null && !isCmsPreviewModeOn, 'editableIndicator': isCmsEditModeOn }">
                                                                            <Editor :id="getEscapedPageId(product.name)"
                                                                            :contentEditable="!isCmsPreviewModeOn"
                                                                            :init="initInlineEditorConf"
                                                                            :initialValue="getEditedTeaser(product.name)"
                                                                            @input="changeSaveCmsButton(getPageId(product.name), true)">
                                                                            </editor>
                                                                    </p>
                                                                    <img
                                                                        v-if="isCmsEditModeOn && visibleSaveCmsButtons[getPageId(product.name)]"
                                                                        class='cms_icon_width'
                                                                        @click="saveDraft(getPageId(product.name))"
                                                                        src="../assets/images/svg/save_draft_icon.svg"
                                                                        title="Save"
                                                                    />
                                                                    <img
                                                                        v-if="isCmsEditModeOn && getDraftTeaser(product.name) != null"
                                                                        class='cms_icon_width'
                                                                        @click="discardDraft(getPageId(product.name))"
                                                                        src="../assets/images/svg/discard_block_change_icon.svg"
                                                                        title="Discard"
                                                                    />
                                                               </div>
                                                               <a v-if="!isCmsEditModeOn" href="javascript:void(0)" @click="redirectTo($routePath.getRouter(product.url))" class="probox_btn" type="button">{{ $t("app.LEARN_MORE") }}</a>
                                                            </div>
                                                            <div v-else class="pro_contbox">
                                                                <img v-if="product.name === 'SmartLeads'" :src="getAssetLocation('smart_leads')" class="img-responsive pro_icon" alt="SmartLeads"
                                                                     title="SmartLeads">
                                                                <img v-if="replaceVars(product.name) === replaceVars('{Dealer} CRM Certification')" :src="getAssetLocation('lead_management')" class="img-responsive pro_icon"
                                                                     :alt="replaceVars(product.name)" :title="replaceVars(product.name)">
                                                                <img v-if="product.name === 'Concierge Elite Service'" :src="getAssetLocation('shopper_contact')" class="img-responsive pro_icon"
                                                                     alt="Concierge Elite Service" title="Concierge Elite Service">
                                                                <img v-if="product.name === 'SmartAnalytics (Dashboard)'" :src="getAssetLocation('smart_analytics')" class="img-responsive pro_icon"
                                                                     alt="SmartAnalytics (Dashboard)" title="SmartAnalytics (Dashboard)">
                                                                <img v-if="replaceVars(product.name) === replaceVars('{Dealer} Website Certification')" :src="getAssetLocation('dwc')" class="img-responsive pro_icon"
                                                                     alt="replaceVars(product.name)" title="replaceVars(product.name)">
                                                                <img v-if="product.name === 'Shopper Contact'" :src="getAssetLocation('shopper_contact')" class="img-responsive pro_icon"
                                                                     alt="Shopper Contact" title="Shopper Contact">

                                                                <div class="product-card" :style="productCardStyle">
                                                                    <h4>{{ $t("app." + replaceVars(product.name)) }}</h4>
                                                                    <p v-if="!isCmsEditModeOn && !isCmsPreviewModeOn"
                                                                        v-html="getTeaser(product.name)">
                                                                    </p>
                                                                    <p v-if="isCmsEditModeOn || isCmsPreviewModeOn"
                                                                        :class="{ 'changedIndicator': getDraftTeaser(product.name) != null && !isCmsPreviewModeOn, 'editableIndicator': isCmsEditModeOn }">
                                                                            <Editor :id="getEscapedPageId(product.name)"
                                                                            :contentEditable="!isCmsPreviewModeOn"
                                                                            :init="initInlineEditorConf"
                                                                            :initialValue="getEditedTeaser(product.name)"
                                                                            @input="changeSaveCmsButton(getPageId(product.name), true)">
                                                                            </editor>
                                                                    </p>
                                                                    <img
                                                                        v-if="isCmsEditModeOn && visibleSaveCmsButtons[getPageId(product.name)]"
                                                                        class='cms_icon_width'
                                                                        @click="saveDraft(getPageId(product.name))"
                                                                        src="../assets/images/svg/save_draft_icon.svg"
                                                                        title="Save"
                                                                    />
                                                                    <img
                                                                        v-if="isCmsEditModeOn && getDraftTeaser(product.name) != null"
                                                                        class='cms_icon_width'
                                                                        @click="discardDraft(getPageId(product.name))"
                                                                        src="../assets/images/svg/discard_block_change_icon.svg"
                                                                        title="Discard"
                                                                    />
                                                                </div>
                                                                <a v-if="!isCmsEditModeOn" href="javascript:void(0)" @click="redirectTo($routePath.getRouter(product.url))" class="probox_btn" type="button">{{ $t("app.LEARN_MORE") }}</a>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xs-12 col-sm-6 col-md-4 pro_line" v-if="hasContactUs">
                                            <div class="">
                                                <a href="javascript:void(0);" @click="redirectWhenNotEditing('contactUs')">
                                                    <div class="pro_contbox">
                                                        <img v-if="isHondaOEM" src="../assets/images/png/honda/honda_Contact_Us.png" class="img-responsive pro_icon min-height-135" alt="Support"
                                                             title="Support">
                                                        <img v-else-if="isAcuraOEM" src="../assets/images/svg/acura_Contact_Us.svg" class="img-responsive pro_icon min-height-135" alt="Support"
                                                             title="Support">
                                                        <img v-else-if="isInfinitiOEM" src="../assets/images/png/infiniti/contactus-infiniti.png" class="img-responsive pro_icon min-height-135" alt="Support"
                                                             title="Support">
                                                        <img v-else :src="getAssetLocation('support')" class="img-responsive pro_icon" alt="Support" title="Support">

                                                      <div class="product-card" :style="productCardStyle">
                                                        <h4>{{ $t("app.CONTACT_US") }}</h4>
                                                        <p v-if="!isCmsEditModeOn && !isCmsPreviewModeOn"
                                                            v-html="getTeaser('Contact Us')" >
                                                        </p>
                                                        <p v-if="isCmsEditModeOn || isCmsPreviewModeOn"
                                                            :class="{ 'changedIndicator': getDraftTeaser('Contact Us') != null && !isCmsPreviewModeOn, 'editableIndicator': isCmsEditModeOn }">
                                                            <Editor :id="getEscapedPageId('Contact Us')"
                                                                :contentEditable="!isCmsPreviewModeOn"
                                                                :init="initInlineEditorConf"
                                                                :initialValue="getEditedTeaser('Contact Us')"
                                                                @input="changeSaveCmsButton(getPageId('Contact Us'), true)">
                                                            </editor>
                                                        </p>
                                                        <img
                                                            v-if="isCmsEditModeOn && visibleSaveCmsButtons[getPageId('Contact Us')]"
                                                            class='cms_icon_width'
                                                            @click="saveDraft(getPageId('Contact Us'))"
                                                            src="../assets/images/svg/save_draft_icon.svg"
                                                            title="Save"
                                                        />
                                                        <img
                                                           v-if="isCmsEditModeOn && getDraftTeaser('Contact Us') != null"
                                                           class='cms_icon_width'
                                                           @click="discardDraft(getPageId('Contact Us'))"
                                                           src="../assets/images/svg/discard_block_change_icon.svg"
                                                           title="Discard"
                                                        />
                                                      </div>
                                                      <a v-if="!isCmsEditModeOn" href="javascript:void(0);" class="probox_btn" type="button">{{ $t("app.LEARN_MORE") }}</a>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              <!-- Product Boxes Ends Here -->
                            </div>
                            <div v-if="isJDPAdmin" class="pro_heading">
                              <h3>Please impersonate a user to see SmartPortal information</h3>
                            </div>
                        </div>
    <simple-modal @modalSubmit="publishHomeCmsChanges" @modalClose="turnOffPublishConfirmationVisibility" :is-visible="isConfirmPublishVisible" modal-title="Confirmation" :is-ok-disabled="false">
        <div>
            <div>
                <p>Are you sure you want to publish changes?</p>
            </div>
        </div>
    </simple-modal>
    <simple-modal @modalSubmit="discardHomeCmsChanges" @modalClose="turnOffCancelConfirmationVisibility" :is-visible="isConfirmCancelVisible" modal-title="Confirmation" :is-ok-disabled="false">
        <div>
            <div>
                <p>Are you sure you want to discard all changes and delete all drafts?</p>
            </div>
        </div>
    </simple-modal>
                      <div v-if="UserDetails && UserDetails.role == 'CMS Editor'">
                        <cmsEditorButton v-if="!isCmsEditModeOn && !isCmsPreviewModeOn" :customMethod="redirectToEnrollments" identifier="enrollments_icon" title="Show Enrollment Form"></cmsEditorButton>
                        <cmsEditorButton v-if="isCmsEditModeOn" :customMethod="turnOnCancelConfirmationVisibility" identifier="discard_all_icon" title="Discard All Changes"></cmsEditorButton>
                        <cmsEditorButton v-if="isCmsEditModeOn" :customMethod="turnOnPublishConfirmationVisibility" identifier="publish_icon" title="Publish Changes"></cmsEditorButton>
                        <cmsEditorButton v-if="isCmsEditModeOn" :customMethod="turnCmsPreviewModeOn" identifier="preview_icon" title="Preview"></cmsEditorButton>
                        <cmsEditorButton v-if="isCmsEditModeOn" :customMethod="turnOffCmsEditModeOn" identifier="disable_editing_icon" title="Disable Editing"></cmsEditorButton>
                        <cmsEditorButton v-if="!isCmsEditModeOn" :customMethod="turnOnCmsEditModeOn" identifier="enable_editing_icon" title="Enable Editing"></cmsEditorButton>
                      </div>
                      <div v-if="!(UserDetails && UserDetails.role == 'CMS Editor') && hasContactUs">
                        <feedback></feedback>
                      </div>
                    </section>
            <!-- Product Section Ends Here -->
                  </span>
          <sso-redirect v-if="this.UserDetails && isSsoLoginOnlyAllowed"></sso-redirect>
        </div>
        <!-- Tab Customer Portal -->
      </div>
      <!-- Tab Content Ends Here -->
    </div>
    <!-- Web Content Ends Here -->
    <!-- Footer -->
    <footer-component></footer-component>
  </div>
</template>

<script>
// @ is an alias to /src
import {mapState} from 'vuex'
import footer from '@/components/footer/footer.vue'
import header from '@/components/header/header.vue'
import topHeader from '@/components/header/topHeader.vue'
import feedback from '@/components/feedbackComponents.vue'
import ssoRedirect from '@/components/ssoRedirect/ssoRedirectContent.vue'
import cmsEditorButton from '@/components/CmsEditorButton.vue'
import {replaceOemCustomLabelPlaceholders} from "@/config/Config";
import ContactDetails from "@/components/manage-profile/contactDetails";
import DWCContactDetails from "@/components/manage-profile/dwc_contactDetails";
import BillingContactDetails from "@/components/manage-profile/billingContactDetails";
import CmsUtil from "../util/CmsUtil";
import Editor from '@tinymce/tinymce-vue';
import SimpleModal from "@/components/modal/SimpleModal";
import CmsEditable from '@/components/mixin/CmsEditable.vue';

export default {
  mixins: [CmsEditable],
  props: ['msg'],
  components: {
    'footer-component': footer,
    'header-component': header,
    'top-header': topHeader,
    'contact_details': ContactDetails,
    'billing_contact_details': BillingContactDetails,
    'dwc_contact_details': DWCContactDetails,
    'sso-redirect': ssoRedirect,
    feedback,
    cmsEditorButton,
    Editor,
    'simple-modal': SimpleModal
  },
  updated() {
    if (this.homePageCmsParams.language.toUpperCase() != CmsUtil.getLanguageParamValue().toUpperCase()) {
        this.loadHomePageCmsData();
    }
    if (!this.loadData && this.UserDetails) {
      var hasProductsOrServices = false;
      this.headerMenuData.forEach(child => {
        if ((!this.isHondaOEM && !this.isAcuraOEM && child.name === 'Products') || (this.isHondaOEM && child.name === 'Services') || (this.isAcuraOEM && child.name === 'Services')) {
          hasProductsOrServices = true;
        }
      });
      if (hasProductsOrServices) {
        this.$store.dispatch('dwc/getDealerEnrollmentDataInfo');
      }
      this.loadData = true;

      // show up profile contact modal for user dealer at end of each quarter
      this.getDealerProfileContactDetails();
    }
  },
  data() {
    return {
      profileData: {
        dealershipCode: null,
        title: null,
        contactDetailsName: null,
        emailAddress: null,
        phoneNumber: null,
        authorizedPerson: null,
        authorizedEmail: null,
        dealerWebsite: null,
        authorizedPhone: null,
        showBillingDetails: null,
        hasAccessOnManageProfile: null,
        lastUpdatedDateForDealerContact: null,
        addonId: null
      },
      homePageCmsParams: {
        oemId: null,
        language: "EN",
        pagesHri: "",
        blocksHri: ""
      },
      homePageCmsData: [],
      videoActive: false,
      loadData: false,
      bannerRenderData: [],
      isBMWActive: false
    }
  },
  computed: {
    ...mapState('dwc', ['UserProfileContactDetails', 'headerMenuData', 'UserDetails']),
    bannerData() {
      const me = this;
      me.bannerRenderData.length = 0;
      me.visitChildrenMenuForCarousel(me.headerMenuData);
      return me.bannerRenderData;
    },
    isHondaOEM() {
      return this.UserDetails && this.UserDetails.oemName === 'Honda';
    },
    isAcuraOEM() {
      return this.UserDetails && this.UserDetails.oemName === 'Acura';
        },
    isInfinitiOEM() {
      return this.UserDetails && this.UserDetails.oemName === 'INFINITI';
        },
    isBMWCA() {
      return this.UserDetails && this.UserDetails.oemName === 'BMWCA';
    },
    isHyundai() {
      return this.UserDetails && this.UserDetails.oemName === 'HYUNDAI';
    },
    isHACC() {
      return this.UserDetails && this.UserDetails.oemName === 'HYUNDAI CA';
    },
    isGenesis() {
      return this.UserDetails && this.UserDetails.oemSkinName === 'genesis';
    },
    isAcura() {
      return this.UserDetails && this.UserDetails.oemSkinName === 'acura';
    },
    isInfiniti() {
      return this.UserDetails && this.UserDetails.oemSkinName === 'infiniti';
    },
    isDealer() {
      return this.UserDetails && this.UserDetails.role.includes('Dealer');
    },
    hasContactUs() {
      const menuItem = this.headerMenuData.find(menuItem => menuItem.filteredChildren.find(x => x.url === "contactUs") != null);
      return menuItem !== undefined;
    },
    productCardStyle() {
      return this.isBMWCA ? 'height: 110px;'
          : this.isGenesis ? 'height: 210px;' : 'height: 170px;';
    },
    isSsoLoginOnlyAllowed() {
      return this.UserDetails ? this.UserDetails.ssoLoginOnlyAllowed : false;
    },
    isAdmin() {
      return this.UserDetails && this.UserDetails.role === 'admin';
    },
    isJDPAdmin() {
      return this.isAdmin && (this.UserDetails.userGroups && this.UserDetails.userGroups.includes('JDP admin'));
    },
    impersonator: function () {
      return this.UserDetails && this.UserDetails.impersonator;
    },
  },
  methods: {
    discardDraft(pageId) {
        this.homePageCmsData
            .filter(item => item.id == pageId)
            .forEach(item => {
                let request = {};
                request.pageId = item.id;
                request.draftIds = item.drafts.map(d => d.id);
                this.$store.dispatch('dwc/discardCmsChanges', request);
                item.drafts = [];
            });
        this.visibleSaveCmsButtons[pageId] = false;
        document.getElementById(this.escapeId(pageId)).innerHTML = this.homePageCmsData.filter(item => item.id == pageId)[0].contentBlocks[0].content;
    },
    saveDraft(pageId) {
       const content = document.getElementById(this.escapeId(pageId)).innerHTML;
       const toSave = CmsUtil.getDraftObjectToSave(pageId, this.homePageCmsData, "PAGE_TEASER", content);
       this.$store.dispatch('dwc/saveCmsDraft', toSave).then((result) => {
            if (!toSave.id) {
                toSave.id = result.data.id;
            }
       });
       this.changeSaveCmsButton(pageId, false);
    },
    publishHomeCmsChanges() {
      new Promise(resolve => {
        let toUpdate = this.homePageCmsData
            .filter(item => item.drafts.length > 0);
        let i = 0;
        let toUpdateLength = toUpdate.length;
            toUpdate.forEach(item => {
                let request = {};
                i++;
                request.pageId = item.id;
                request.draftIds = item.drafts.map(d => d.id);
                this.$store.dispatch('dwc/publishCmsChanges', request).then((res) => {
                    if (i == toUpdateLength) {
                        this.changesId += 1;
                    }
                });
            });
        resolve();
      }).then((result) => {
                this.isCmsEditModeOn = false;
                this.isCmsPreviewModeOn = false;
                this.turnOffPublishConfirmationVisibility();
                this.visibleSaveCmsButtons = {};
      });
    },
    discardHomeCmsChanges() {
        this.homePageCmsData
            .filter(item => item.drafts.length > 0)
            .forEach(item => {
                let request = {};
                request.pageId = item.id;
                request.draftIds = item.drafts.map(d => d.id);
                this.$store.dispatch('dwc/discardCmsChanges', request)
            });
        this.isCmsEditModeOn = false;
        this.isCmsPreviewModeOn = false;
        this.homePageCmsData.forEach(item => item.drafts = []);
        this.turnOffCancelConfirmationVisibility();
        this.visibleSaveCmsButtons = {};
    },
    redirectWhenNotEditing(url) {
        if (!this.isCmsEditModeOn && !this.isCmsPreviewModeOn) {
            this.redirectTo(this.$routePath.getRouter(url))
        }
    },
    redirectToEnrollments() {
        this.$router.push('/dwcpenrollment');
    },
    loadHomePageCmsData() {
        if (this.UserDetails) {
            this.homePageCmsParams = CmsUtil.prepareHomePageCmsDataPayload(this.UserDetails.oemId, this.headerMenuData, this.hasContactUs);
    	    this.$store.dispatch('dwc/getPageCms', this.homePageCmsParams).then((result) => {
    			this.homePageCmsData = result.data;
    		});
    	}
    },
    getPageId(component) {
        return CmsUtil.getPageId(component, this.homePageCmsData);
    },
    getEscapedPageId(component) {
        return 'id_' + CmsUtil.getPageId(component, this.homePageCmsData);
    },
    getDraftTeaser(component) {
        return CmsUtil.getDraftBlockContentForPage(component, this.homePageCmsData, "PAGE_TEASER");
    },
    getEditedTeaser(component) {
        const draft = this.getDraftTeaser(component);
        return draft != null ? draft : CmsUtil.getBlockContent(component, this.homePageCmsData, "PAGE_TEASER");
    },
    getTeaser(component) {
        return CmsUtil.getBlockContent(component, this.homePageCmsData, "PAGE_TEASER");
    },
    showUpProfileContactModalAtEndOfEachQuarter() {
      const me = this;
      if (this.UserDetails.role.includes('Dealer') && this.UserDetails.oemName !== 'HYUNDAI CA') {
        let lastUpdatedDate;
        if(me.profileData.lastUpdatedDateForDealerContact) {
          lastUpdatedDate = new Date(me.profileData.lastUpdatedDateForDealerContact);
        }
        let cancelledDateForContactModal = window.localStorage.getItem('profileContactModalCancelled');
        if (me.profileData.hasAccessOnManageProfile && !lastUpdatedDate
            || (((lastUpdatedDate && this.isTodayEndOfQuarter() && !this.isToday(lastUpdatedDate)) || (lastUpdatedDate && this.isGreaterThan90Days(lastUpdatedDate)))
                && (!cancelledDateForContactModal || (cancelledDateForContactModal && cancelledDateForContactModal != this.UserDetails.lastLoginTime)))) { // to show profile contact modal on refresh login in case the user cancelled the update at any stage of update
          me.isProfileContactModalOpen = !me.isProfileContactModalOpen;
          $("body").addClass("modal-open");
        }
      }
    },
    getDealerProfileContactDetails() {
      const me = this;
      if(!me.isDealer || me.impersonator || this.UserDetails.oemName === 'HYUNDAI CA') {
        return;
      }
      this.$store.dispatch('dwc/getUserProfileContactDetails')
          .then(() => {
            if (me.UserProfileContactDetails) {
              me.profileData.title = me.UserProfileContactDetails.contactDetails.title;
              me.profileData.contactDetailsName = me.UserProfileContactDetails.contactDetails.name;
              me.profileData.emailAddress = me.UserProfileContactDetails.contactDetails.email;
              me.profileData.dealerWebsite = me.UserProfileContactDetails.dwcpEnrollmentContactDetailsData.dealerWebsite;
              me.profileData.phoneNumber = me.UserProfileContactDetails.contactDetails.phone;
              me.profileData.authorizedPerson = me.UserProfileContactDetails.dwcpEnrollmentContactDetailsData.authorizedPerson;
              me.profileData.addonId = me.UserProfileContactDetails.dwcpEnrollmentContactDetailsData.addonId;
              me.profileData.authorizedEmail = me.UserProfileContactDetails.dwcpEnrollmentContactDetailsData.authorizedEmail;
              me.profileData.authorizedPhone = me.UserProfileContactDetails.dwcpEnrollmentContactDetailsData.authorizedPhone;
              me.profileData.lastUpdatedDateForDealerContact = me.UserProfileContactDetails.dwcpEnrollmentContactDetailsData.lastUpdatedDateForDealerContact;
              me.profileData.hasAccessOnManageProfile = me.UserProfileContactDetails.hasAccessOnManageProfile;
              me.profileData.showBillingDetails = me.UserProfileContactDetails.showBillingDetails;
              this.showUpProfileContactModalAtEndOfEachQuarter();
            }
          });
    },
    isTodayEndOfQuarter() {
      const currentYear = new Date().getFullYear();
      const fixedQuarterDates = [new Date(currentYear, 2, 31), new Date(currentYear, 5, 30), new Date(currentYear, 8, 30), new Date(currentYear, 11, 31)];
      let expectedQuarterDates = window.localStorage.getItem('expectedQuarterDates');
      expectedQuarterDates = expectedQuarterDates ? expectedQuarterDates.split(",").map(d => new Date(d)) : [];
      if (expectedQuarterDates.length <= 0) {
        for (let i = 0; i < fixedQuarterDates.length; i++) {
          if (fixedQuarterDates[i].getDay() == 0) {
            fixedQuarterDates[i].setDate(fixedQuarterDates[i].getDate() - 2);
          } else if (fixedQuarterDates[i].getDay() == 6) {
            fixedQuarterDates[i].setDate(fixedQuarterDates[i].getDate() - 1);
          }
          expectedQuarterDates.push(fixedQuarterDates[i]);
        }
        window.localStorage.setItem('expectedQuarterDates', expectedQuarterDates);
      }

      let isTodayEndOfQuarter = false;
      for (let j = 0; j < expectedQuarterDates.length; j++) {
        if (this.isToday(expectedQuarterDates[j])) {
          isTodayEndOfQuarter = true;
          break;
        }
      }
      return isTodayEndOfQuarter;
    },
    isGreaterThan90Days(lastUpdatedDate) {
      return (Math.round(Math.abs(new Date() - lastUpdatedDate) / (1000 * 60 * 60 * 24)) > 90);
    },
    isToday(date) {
      const currentDate = new Date();
      if (currentDate.getFullYear() === date.getFullYear() &&
          currentDate.getMonth() === date.getMonth() &&
          currentDate.getDate() === date.getDate()) {
        return true;
      }
      return false;
    },
    visitChildrenMenuForCarousel(productDetails) {
      if (!productDetails) {
        return;
      }
      let me = this;
      for (let i = 0; i < productDetails.length; i++) {
        const product = productDetails[i].name;
        if (product === 'SmartLeads') {
          const text = this.getBannerText(product);
          me.bannerRenderData.push('<div class="item smartleads_hero"><div class="container">' + text + '<a href="smartleads"><button class="slide_btn smarleadtbtn" type="button">{{ $t("app.LEARN_MORE") }}</button></a></div></div>');
        } else if (product === 'Shopper Contact') {
          const text = this.getBannerText(product);
          me.bannerRenderData.push('<div class="item shoppercontact_hero"><div class="container">' + text + '<a href="shoppercontact"><button class="slide_btn scbtn" type="button">{{ $t("app.LEARN_MORE") }}</button></a></div></div>');
        } else if (this.replaceVars(product) === this.replaceVars('{Dealer} Website Certification')) {
          const text = this.getBannerText(product);
          if (this.isHACC) {
            me.bannerRenderData.push('<div class="item dwc_hero_hacc"><div class="container">' + text + '<a href="dealerWebsiteCertification"><button class="slide_btn dwcbtn" type="button">{{ $t("app.LEARN_MORE") }}</button></a></div></div>');
          } else {
            me.bannerRenderData.push('<div class="item dwc_hero"><div class="container">' + text + '<a href="dealerWebsiteCertification"><button class="slide_btn dwcbtn" type="button">{{ $t("app.LEARN_MORE") }}</button></a></div></div>');
          }
        } else if (this.replaceVars(product) === this.replaceVars('{Dealer} CRM Certification')) {
          const text = this.getBannerText(product);
          if (this.isHACC) {
            me.bannerRenderData.push('<div class="item crm_hero_hacc"><div class="container">' + text + '<a href="leadmanagement"><button class="slide_btn dwcbtn" type="button">{{ $t("app.LEARN_MORE") }}</button></a></div></div>');
          } else {
            me.bannerRenderData.push('<div class="item crm_hero"><div class="container">' + text + '<a href="leadmanagement"><button class="slide_btn dwcbtn" type="button">{{ $t("app.LEARN_MORE") }}</button></a></div></div>');
          }
        } else if (product === 'Concierge Elite Service') {
          const text = this.getBannerText(product);
          me.bannerRenderData.push('<div class="item concierge_hero"><div class="container">' + text + '<a href="concierge"><button class="slide_btn concbtn" type="button">{{ $t("app.LEARN_MORE") }}</button></a></div></div>');
        } else if (product === 'SmartAnalytics (Dashboard)') {
          if (this.isGenesis) {
            const text = this.getBannerText(product);
            me.bannerRenderData.push('<div class="item smartanalytics_hero"><div class="container">' + text + '<a href="smartAnalytics"><button class="slide_btn smartanalyticsbtn" type="button">{{ $t("app.LEARN_MORE") }}</button></a></div></div>');
          } else if (this.isHACC) {
            me.bannerRenderData.push('<div class="item smartanalytics_hero"><a href="smartAnalytics"><button class="slide_btn smartanalyticsbtn" type="button" disabled>{{ $t("app.COMING_SOON") }}</button></a></div>');
          } else {
            me.bannerRenderData.push('<div class="item smartanalytics_hero"><a href="smartAnalytics"><button class="slide_btn smartanalyticsbtn" type="button">{{ $t("app.CHECK_TODAY") }}</button></a></div>')
          }
        }
        me.visitChildrenMenuForCarousel(productDetails[i].filteredChildren);
      }
    },
    getBannerText(product) {
      if (!this.isGenesis) {
        return '';
      }
      const genesisTexts = {
        'SmartLeads': {
          header: 'SmartLeads delivers buyers with intent',
          text: "The industry's best 3rd party lead program"
        },
        'Dealer Website Certification': {
          header: "You are in the driver's seat",
          text: 'Choose from best-in-class Dealer Website Providers'
        },
        'Concierge Elite Service': {
          header: 'Concierge Elite',
          text: 'Professionally trained agents scheduling your appointments 24/7'
        },
        'SmartAnalytics (Dashboard)': {
          header: 'Get actionable insights with Smart Analytics',
          text: 'Drive more sales with intelligent data'
        }
      };

      let texts = genesisTexts[product];
      if (texts) {
        return `<div class="banner-text"><h1>${texts.header}</h1><p>${texts.text}</p></div>`;
      }
      return '';
    },
    playVideo(elementId) {
      this.videoActive = true;
      document.getElementById(elementId).play();
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';

    },
    closeVideo() {
      document.body.style.overflow = 'auto';

      this.videoActive = false;
      let hondaVideo = document.getElementById("hondaVideo");
      if (hondaVideo) {
        hondaVideo.pause();
        hondaVideo.currentTime = 0;
      }
      let acuraVideo = document.getElementById("acuraVideo");
      if (acuraVideo) {
        acuraVideo.pause();
        acuraVideo.currentTime = 0;
      }
    },
    getAssetLocation(name) {
      return this.isGenesis ? require('../assets/images/png/genesis/home/' + name + '.png') : require('../assets/images/svg/' + name + '.svg');
    },
    redirectTo(path) {
      this.$router.push(path).catch((err) => {
        throw new Error(`Problem redirecting to ${path}: ${err}.`);
      });
    },
    redirectToNewTab(path) {
      window.open(window.location.origin + "" + path, '_blank');
    },
    replaceVars(body) {
      return replaceOemCustomLabelPlaceholders(this.UserDetails, body);
    }
  },
   watch: {
     UserDetails: {
       immediate: true,
        handler() {
         this.loadHomePageCmsData();
        }
      },
      changesId(newValue, oldValue) {
         this.loadHomePageCmsData();
      }
   }
}
</script>
